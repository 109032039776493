<template>
    <div class="tabContainer mt-5">
        <div class="buttonContainer">
            <b-button @click="$emit('change-active-tab', 'semua')" :class="{ active: activeTab == 'semua' }"
                class="tabCustom">Semua</b-button>
            <b-button @click="$emit('change-active-tab', 'reksadana')" :class="{ active: activeTab == 'reksadana' }"
                class="tabCustom">Reksa
                Dana</b-button>
            <!-- <b-button @click="$emit('change-active-tab', 'obligasi')" :class="{ active: activeTab == 'obligasi' }"
                class="tabCustom">Obligasi</b-button> -->
        </div>

        <b-row cols="1" cols-md="3">
            <b-col v-if="filterDoneOrFailed" class="mb-3 mb-md-0">
                <b-form-select @change="$emit('change-done-filter', $event)" class="selectOrder"
                    :value="filterDoneOrFailedValue">
                    <b-form-select-option :value="'ALL'">ALL</b-form-select-option>
                    <b-form-select-option :value="'BERHASIL'">BERHASIL</b-form-select-option>
                    <b-form-select-option :value="'GAGAL'">GAGAL</b-form-select-option>
                </b-form-select>
            </b-col>
            <b-col>
                <b-form-select @change="$emit('change-order', $event)" class="selectOrder" :value="orderBy">
                    <b-form-select-option :value="'DESC'">ORDER DESC BY DATE</b-form-select-option>
                    <b-form-select-option :value="'ASC'">ORDER ASC BY DATE</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'ButtonListActity',
    props: {
        activeTab: {
            type: String,
            required: true
        },
        orderBy: {
            type: String,
            required: true
        },
        filterDoneOrFailedValue: {
            type: String,
        },
        filterDoneOrFailed: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.tabContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .buttonContainer {
        display: flex;
        gap: 12px;
        align-items: center;

        .tabCustom {
            flex-shrink: 0;
            background-color: #FFF !important;
            color: #6B7280;
            border-radius: 4px;
            padding: 7px 24px;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            border-width: 1px;
            border-style: solid;
            border-color: transparent;
            transition: 0.3s;

            &.active {
                border-color: #EE503F !important;
                color: #5E1108 !important;
                background-color: #FFF5F5 !important;
            }
        }
    }


    .selectOrder {
        width: max-content;
    }

    @media (min-width: 576px) {}

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        gap: 0px;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {}

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {}

}
</style>
