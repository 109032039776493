<template>
  <div class="footer">
    <b-container class="footer-container">
      <b-row class="justify-content-md-center footer-row">
        <div class="col-lg-9 col-md-6">
          <p>
            &#64; {{ new Date().getFullYear() }} PT. CGS International Sekuritas Indonesia Berizin dan Diawasi oleh Otoritas Jasa Keuangan
          </p>
        </div>
        <div class="col-lg-3 col-md-6 nav-link-footer">
          <b-row>
            <div class="mx-2">
              <b-link class="footer-link" :href="`${frontendLink}/hubungi-kami`" target="_blank"
                >Kontak</b-link
              >
            </div>
            <div class="mx-2">
              <b-link class="footer-link" :href="`${frontendLink}/karir`" target="_blank"
                >Karir</b-link
              >
            </div>
            <div class="mx-2">
              <b-link class="footer-link" :href="`${frontendLink}/syarat-dan-ketentuan`" target="_blank"
                >Syarat & Ketentuan</b-link
              >
            </div>
          </b-row>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Footer3",
  data () {
    return ({
      frontendLink: process.env.VUE_APP_ROOT_FRONTEND,
    })
  }
};
</script>

<style lang="scss" scoped>
.footer-container {
  padding-top: 60vh;
  padding-bottom: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: white;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.11em;
  color: #6b7280;
}

h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #d1d5db;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #697386;
}
.footer-link {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #697386;
  text-decoration: none;
}
@media only screen and (max-width: 768px) {
  .footer-row {
    text-align: center;
  }
  .nav-link-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
