<template>
    <b-card @click="isOpen = isOpen ? false : true" class="list-activity-card mt-4">
        <b-row class="d-md-none">
            <div class="col-lg-4 col-md-6 my-auto p-0">
                <div class="fund-name">
                    <div class="container">
                        <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                            value.bond_id
                            " />
                        <div>
                            <p class="heading">Sukuk Ritel</p>
                            <h4>{{ value.bond_id }}</h4>
                        </div>
                    </div>
                </div>
            </div>

            <b-container class="mt-5">
                <div class="col-lg-2 col-md-6 my-auto p-0">
                    <div class="investment-value">
                        <div>
                            <p>Nilai Investasi</p>
                            <h3 class="mt-n2">
                                IDR {{ formatPrice(value.order_type === 1 ? value.buyervolume : value.sellervolume) }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 my-auto p-0">
                    <div class="investment-value">
                        <div>
                            <p>Tanggal Pembelian</p>
                            <h3 class="mt-n2">
                                {{ formatter(value.updated_at) }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 my-auto p-0">
                    <div class="investment-value">
                        <div>
                            <p>Jenis Transaksi</p>
                            <div v-if="done" class="status-message"
                                :class="{ success: value.order_type === 1, danger: value.order_type === 2 }">
                                <p v-if="value.order_type === 1">Pembelian</p>
                                <p v-else-if="value.order_type === 2">Penjualan</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-container>
        </b-row>

        <b-row class="d-none d-md-flex">
            <div class="col-lg-4 col-md-6 my-auto p-0">
                <div class="fund-name">
                    <div class="container">
                        <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                            value.bond_id
                            " />
                        <div>
                            <p class="heading">Sukuk Ritel</p>
                            <h4>{{ value.bond_id }}</h4>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 my-auto p-0">
                <div class="investment-value">
                    <div>
                        <p>Nilai Investasi</p>
                        <h3 class="mt-n2">
                            IDR {{ formatPrice(value.order_type === 1 ? value.buyervolume : value.sellervolume) }}
                        </h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 my-auto p-0">
                <div class="investment-value">
                    <div v-if="done">
                        <p>{{ moment(value.updated_at).format('DD MMM YYYY') }}</p>
                        <h3 :class="[value.status == 2 ? 'danger' : 'success']" class="status mt-n2">
                            {{ value.order_type == 2 ? "Penjualan" : "Pembelian" }} {{ value.status === 2 ? "Gagal" :
                                "Berhasil"
                            }}
                        </h3>
                    </div>
                    <div v-else>
                        <p :class="[value.order_type === 2 ? 'danger' : 'success']">{{ value.order_type == 2 ? "Penjualan" :
                            "Pembelian" }}</p>
                        <h3 class="status mt-n2" v-if="value.status == 0">
                            Dalam Proses
                        </h3>
                        <h3 class="status mt-n2" v-else-if="value.status == 2">
                            Gagal
                        </h3>
                        <h3 class="status mt-n2" v-else>
                            Berhasil
                        </h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 text-right my-auto">
                <div class="timer-buy">
                    <b-row class="justify-content-center justify-content-lg-end">
                        <div>
                            <div class="
                            col-lg-12 col-md-6
                            my-auto
                            text-left text-lg-right
                          ">
                                <div class="estimation text-right">
                                    <p class="ml-0">Tanggal Pembelian</p>
                                    <h3 class="mt-n2 text-right">
                                        {{ formatter(value.updated_at) }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </b-row>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 text-right my-auto">
                <div v-if="done" class="status-message"
                    :class="{ success: value.order_type === 1, danger: value.order_type === 2 }">
                    <p v-if="value.order_type === 1">Pembelian</p>
                    <p v-else-if="value.order_type === 2">Penjualan</p>
                </div>
                <div v-else class="timer-buy">
                    <b-row class="justify-content-center justify-content-lg-end" v-if="!done">
                        <div>
                            <div class="
                            col-lg-12 col-md-6
                            my-auto
                            text-left text-lg-right
                          ">
                                <div class="estimation text-right">
                                    <p class="ml-0">Estimasi Selesai</p>
                                    <h3 class="mt-n2 text-right">
                                        {{ nextDayBussiness(value.updated_at) }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </b-row>
                </div>
            </div>
        </b-row>
        <b-row class="mt-3" v-if="isOpen">
            <b-col>
                <div class="col-lg-2 col-md-6 my-auto p-0">
                    <div class="investment-value">
                        <div>
                            <p>Nominal Total</p>
                            <h3 class="mt-n2">
                                IDR {{ formatPrice(value?.nominal_total) }}
                            </h3>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import moment from 'moment-business-days';
export default {
    name: 'itemTransactionObligasi',
    data() {
        return ({
            isOpen: false,
        });
    },
    props: {
        value: Object,
        done: Boolean
    },
    methods: {
        nextDayBussiness(date) {
            return moment(date).businessAdd('2', 'days').format('DD MMM YYYY')
        },
        formatter(val) {
            return moment(val).format('DD MMM YYYY')
        }
    },
};
</script>

<style scoped lang="scss">
.list-activity-card {
    box-shadow: 0px 1px 2px rgba(107, 114, 128, 0.06),
        0px 1px 3px rgba(107, 114, 128, 0.15);
    border-radius: 6px;

    .fund-name {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .container {
            display: flex;
            align-items: flex-end;
            gap: 12px;
        }

        img {
            border-radius: 6px;
            width: 40px;
            flex-shrink: 0;
        }

        h4 {
            font-weight: 700;
            font-size: 16px;
            color: #0B318F;
            margin: 0;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            color: #6b7280;
            margin: 0;
        }
    }

    .investment-value {
        .status.black {
            color: black !important;
        }

        .status {
            color: #EE503F;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
        }

        p,
        h3 {
            &.success {
                color: #2F855A;
                font-weight: bold;
            }

            &.danger {
                color: #C53030;
                font-weight: bold;
            }

            &.warning {
                color: #C05621;
                font-weight: bold;
            }
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #6b7280;
        }

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;

            color: #111827;
        }
    }

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;

        color: #111827;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #4b5563;
    }

    .estimation {
        p {
            color: #6B7280 !important;
            font-family: Inter !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 150% !important;
        }

        h3 {
            color: #111827;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            /* 24px */
        }
    }

    .timer-buy {
        p {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;

            color: #e60012;
        }
    }

    .status-message {
        padding: 6px 12px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        margin: 0 auto 0 0;

        @media screen and (min-width: 768px) {
            & {
                margin: 0 0 0 auto;
            }
        }

        p {
            margin: 0;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        &.success {
            background: #C6F6D5;

            p {
                color: #2F855A;
            }
        }

        &.danger {
            background: #FED7D7;

            p {
                color: #C53030;
            }
        }

        &.warning {
            background: #FEEBC8;

            p {
                color: #C05621;
            }
        }
    }

    .btn-buy {
        font-family: $inter;
        background: #FF3F4E;
        border-radius: 4px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        border: none;
        color: white;
    }

    .btn-buy:hover {
        background: $hoverSecondary;
    }

    .btn-buy:focus {
        box-shadow: none !important;
    }

    .btn-buy:active {
        background: $hoverSecondary !important;
        outline: none !important;
    }

    @media only screen and (max-width: 768px) {
        h4 {
            font-size: 14px !important;
        }

        h3 {
            font-size: 14px !important;
        }

        p {
            font-size: 14px !important;
        }
    }
}
</style>